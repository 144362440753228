var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fixed top-0 w-full flex justify-center ease-in duration-200",class:{
    'bg-white': _vm.$route.path === '/newsList' || !_vm.isTop,
    'min-w-1200': !_vm.isMobile,
  },staticStyle:{"z-index":"3000"}},[(!_vm.isMobile)?_c('div',{staticClass:"min-w-1200 h-16 text-footer-color md:w-bodySet flex justify-between items-center ease-in duration-500"},[_c('div',{staticClass:"flex items-center"},[_c('img',{attrs:{"src":require("@/assets/images/ycclogo.png"),"alt":"logo","width":"164"}}),_c('ul',{staticClass:"header_menu flex leading-normal text-sm ml-16"},[_c('router-link',{staticClass:"pl-5 pr-5 cursor-pointer relative",class:{
            ' text-white': _vm.$route.path === '/blockChainBrowser' && _vm.isTop,
            ' font-bold': _vm.$route.path === '/home',
            ' text-text-color': _vm.$route.path !== '/home',
          },attrs:{"to":{ path: '/home', query: { lang: _vm.$route.query.lang } },"tag":"li"}},[_vm._v(" "+_vm._s(_vm.$t('lang.home'))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.path === '/home'),expression:"$route.path === '/home'"}],staticClass:"absolute block w-7 h-1 bg-footer-color rounded-2xl left-1/2 -bottom-2 transform -translate-x-1/2"})]),_c('li',{staticClass:"pl-5 pr-5 cursor-pointer",class:{
            ' text-white': _vm.$route.path === '/blockChainBrowser' && _vm.isTop,
            ' text-text-color': _vm.$route.path !== '/bps',
          }},[_c('a',{staticClass:"transition-none hover:text-current",attrs:{"href":_vm.iconType === 'zh-CN'
                ? 'https://zbzb.s3.ap-northeast-1.amazonaws.com/yuan.org/ycc/white-paper/zh.pdf'
                : 'https://zbzb.s3.ap-northeast-1.amazonaws.com/yuan.org/ycc/white-paper/en/YUANCHAIN-Whitepaper.pdf',"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('lang.book'))+" ")])]),_c('router-link',{staticClass:"pl-5 pr-5 cursor-pointer relative",class:{
            ' text-white': _vm.$route.path === '/blockChainBrowser' && _vm.isTop,
            ' font-bold': _vm.$route.path === '/newsList',
            ' text-text-color': _vm.$route.path !== '/newsList',
          },attrs:{"to":{ path: '/newsList', query: { lang: _vm.$route.query.lang } },"tag":"li"}},[_vm._v(" "+_vm._s(_vm.$t('lang.news'))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.$route.path === '/newsList' || _vm.$route.path === '/newsDetails'
            ),expression:"\n              $route.path === '/newsList' || $route.path === '/newsDetails'\n            "}],staticClass:"absolute block w-7 h-1 bg-footer-color rounded-2xl left-1/2 -bottom-2 transform -translate-x-1/2"})]),_c('router-link',{staticClass:"pl-5 pr-5 cursor-pointer relative",class:{
            ' text-white': _vm.$route.path === '/blockChainBrowser' && _vm.isTop,
            ' font-bold':
              _vm.$route.path === '/blockChainBrowser' ||
              _vm.$route.path === '/block' ||
              _vm.$route.path === '/blockDetail' ||
              _vm.$route.path === '/tradeHash' ||
              _vm.$route.path === '/tradeList' ||
              _vm.$route.path === '/address',
            ' text-text-color': !(
              _vm.$route.path === '/blockChainBrowser' ||
              _vm.$route.path === '/block' ||
              _vm.$route.path === '/blockDetail' ||
              _vm.$route.path === '/tradeHash' ||
              _vm.$route.path === '/tradeList' ||
              _vm.$route.path === '/address'
            ),
          },attrs:{"to":{
            path: '/blockChainBrowser',
            query: { lang: _vm.$route.query.lang },
          },"tag":"li"}},[_vm._v(" "+_vm._s(_vm.$t('lang.blockChainBrowser'))+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.$route.path === '/blockChainBrowser' ||
              _vm.$route.path === '/block' ||
              _vm.$route.path === '/blockDetail' ||
              _vm.$route.path === '/tradeHash' ||
              _vm.$route.path === '/tradeList' ||
              _vm.$route.path === '/address'
            ),expression:"\n              $route.path === '/blockChainBrowser' ||\n              $route.path === '/block' ||\n              $route.path === '/blockDetail' ||\n              $route.path === '/tradeHash' ||\n              $route.path === '/tradeList' ||\n              $route.path === '/address'\n            "}],staticClass:"absolute block w-7 h-1 bg-footer-color rounded-2xl left-1/2 -bottom-2 transform -translate-x-1/2",class:{
              ' bg-white': _vm.$route.path === '/blockChainBrowser' && _vm.isTop,
            }})])],1)]),_c('div',{staticClass:"flex text-sm mr-1 items-center"},[_c('p',{staticClass:"mr-20",class:{ 'text-white': _vm.isTop },style:({ color: _vm.$route.path === '/home' ? '' : '#2545CB' })},[_vm._v(" "+_vm._s(_vm.$t('lang.email'))+"： business@yuan.org ")]),_c('div',{staticClass:"relative"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.iconType === 'zh-CN'),expression:"iconType === 'zh-CN'"}],staticClass:"flex items-center justify-center cursor-pointer",on:{"click":function($event){_vm.showIcon = !_vm.showIcon}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/header/ZH.png"),"alt":"中文"}}),_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.$t('lang.zh')))]),_c('img',{staticClass:"ml-1",attrs:{"width":"10","src":require("@/assets/images/header/arrow_r.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.iconType === 'en'),expression:"iconType === 'en'"}],staticClass:"flex items-center justify-center cursor-pointer",on:{"click":function($event){_vm.showIcon = !_vm.showIcon}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/header/EN.png"),"alt":"英文"}}),_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.$t('lang.en')))]),_c('img',{staticClass:"ml-1",attrs:{"width":"10","src":require("@/assets/images/header/arrow_r.png"),"alt":""}})]),_c('icon-select',{attrs:{"show":_vm.showIcon},on:{"hidden":function($event){_vm.showIcon = false},"selectIcon":function (v) {
              _vm.iconType = v
            }}})],1)])]):_c('div',{staticClass:"w-full flex justify-between items-center bg-white",staticStyle:{"height":"44px"},style:({
      'border-bottom': _vm.$route.path !== '/home' ? '1px solid #F2F2F2' : 'none',
    })},[_vm._m(0),_c('div',{staticClass:"flex justify-between items-center"},[_c('div',{staticClass:"relative"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.iconType === 'zh-CN'),expression:"iconType === 'zh-CN'"}],staticClass:"flex items-center justify-center cursor-pointer",on:{"click":function($event){_vm.showIcon = !_vm.showIcon}}},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/images/header/ZH.png"),"alt":"中文"}}),_c('p',{staticClass:"text-xs text-footer-color"},[_vm._v(_vm._s(_vm.$t('lang.zh')))]),_c('img',{staticClass:"mr-1",attrs:{"width":"10","src":require("@/assets/images/header/arrow_r.png"),"alt":""}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.iconType === 'en'),expression:"iconType === 'en'"}],staticClass:"flex items-center justify-center cursor-pointer",on:{"click":function($event){_vm.showIcon = !_vm.showIcon}}},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/images/header/EN.png"),"alt":"英文"}}),_c('p',{staticClass:"text-xs text-footer-color"},[_vm._v(_vm._s(_vm.$t('lang.en')))]),_c('img',{staticClass:"mr-1",attrs:{"width":"10","src":require("@/assets/images/header/arrow_r.png"),"alt":""}})]),_c('icon-select',{attrs:{"show":_vm.showIcon},on:{"hidden":function($event){_vm.showIcon = false},"selectIcon":function (v) {
              _vm.iconType = v
            }}})],1),_c('div',{staticClass:"mr-5 w-6 h-6",on:{"click":function($event){;(_vm.showMenu = !_vm.showMenu), (_vm.showIcon = false)}}},[_c('img',{staticClass:"w-full h-full",attrs:{"src":require("@/assets/images/header/menu.png"),"alt":""}})])])]),_c('Menu',{attrs:{"isShow":_vm.showMenu,"iconType":_vm.iconType},on:{"close":_vm.closeMenu}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo mx-3.5"},[_c('img',{attrs:{"src":require("@/assets/images/ycclogo.png"),"alt":"logo","width":"140"}})])}]

export { render, staticRenderFns }