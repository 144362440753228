













import locale from 'element-ui/lib/locale'
import enLocale from "element-ui/lib/locale/lang/en";
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import moment from 'moment';

import Vue from 'vue'
export default Vue.extend({
  props: {
    show: Boolean
  },
  data() {
    return {
      
    }
  },
  methods: {
    selectLanguage(v: string) {
      if(v === 'zh-CN'){
        locale.use(zhLocale)
      }else {
        locale.use(enLocale)
      }
      this.$emit('selectIcon', v)
      this.$emit('hidden', true)
      window.sessionStorage.setItem('language', v)
      this.$i18n.locale = v === 'zh-CN' ? 'zh' : 'en'
        document.title = `${this.$t('lang.YUANLIAN')}YCC——${this.$t(
        'lang.title',
      )}`
      moment.locale(v === 'zh-CN' ? 'zh-cn' : 'en')
      this.$router.replace({ path: this.$route.path, query: { ...this.$route.query, lang: v }})
    }
  },
})
