





































































































































































































































import Vue from 'vue'
import IconSelect from '@/components/iconSelect.vue'
import Menu from '@/components/mobile/menu.vue'
import { checkIsMobile } from '@/utils/utils'
import moment from 'moment'
export default Vue.extend({
  components: {
    IconSelect,
    Menu,
  },
  data() {
    return {
      isTop: true,
      showIcon: false,
      iconType: 'zh-CN',
      showMenu: false,
    }
  },
  methods: {
    closeMenu(v: boolean) {
      this.showMenu = !v
    },
  },
  computed: {
    isMobile() {
      return checkIsMobile()
    },
  },
  mounted() {
    const scrollY = window.scrollY
    const type = this.$route.query as any
    this.iconType =
      type.lang || window.sessionStorage.getItem('language') || 'zh-CN'
    window.sessionStorage.setItem(
      'language',
      window.sessionStorage.getItem('language') || this.iconType,
    )
    this.$i18n.locale = this.iconType === 'zh-CN' ? 'zh' : 'en'
    this.isTop = scrollY < 70 ? true : false
    window.addEventListener('scroll', () => {
      const scrollY = window.scrollY
      this.isTop = scrollY < 70 ? true : false
    })
    moment.locale(this.iconType === 'zh-CN' ? 'zh-cn' : 'en')
    document.title = `${this.$t('lang.YUANLIAN')}YCC——${this.$t(
      'lang.title',
    )}`
  },
  watch: {
    $route(n, o) {
      const scrollY = window.scrollY
      this.iconType =
        window.sessionStorage.getItem('language') || n.query.lang || 'zh-CN'
      moment.locale(this.iconType === 'zh-CN' ? 'zh-cn' : 'en')
      this.isTop = scrollY < 70 ? true : false
      window.addEventListener('scroll', () => {
        const scrollY = window.scrollY
        this.isTop = scrollY < 70 ? true : false
      })
    },
  },
})
