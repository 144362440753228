






























































import Vue from 'vue'
import { Drawer } from 'ant-design-vue'
export default Vue.extend({
  components: {
    Drawer,
  },
  props: {
    isShow: Boolean,
    iconType: {
      type: String,
      default: 'zh-CN',
    },
  },
  methods: {
    onClose() {
      this.$emit('close', true)
    },
    goPage(p: string) {
      this.$router.push({ path: p, query: { lang: this.$route.query.lang } })
      this.onClose()
    },
  },
})
