var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu"},[_c('Drawer',{staticClass:"my-drawer",attrs:{"width":"270","closable":true,"visible":_vm.isShow},on:{"close":_vm.onClose}},[_c('div',{staticClass:"logo mb-3.5 px-6"},[_c('img',{attrs:{"src":require("@/assets/images/ycclogo.png"),"width":"140","alt":"yuanlian"}})]),_c('ul',{staticClass:"menulist"},[_c('li',{staticClass:"px-6 text-base text-title-color",class:{ 'menu-active': _vm.$route.path === '/home' },on:{"click":function($event){return _vm.goPage('/')}}},[_vm._v(" "+_vm._s(_vm.$t('lang.home'))+" ")]),_c('li',{staticClass:"px-6 text-base text-title-color"},[_c('a',{attrs:{"href":_vm.iconType === 'zh-CN'
              ? 'https://zbzb.s3.ap-northeast-1.amazonaws.com/yuan.org/ycc/white-paper/zh.pdf'
              : 'https://zbzb.s3.ap-northeast-1.amazonaws.com/yuan.org/ycc/white-paper/en/YUANCHAIN-Whitepaper.pdf',"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('lang.book'))+" ")])]),_c('li',{staticClass:"px-6 text-base text-title-color",class:{
          'menu-active':
            _vm.$route.path === '/newsList' || _vm.$route.path === '/newsDetails',
        },on:{"click":function($event){return _vm.goPage('/newsList')}}},[_vm._v(" "+_vm._s(_vm.$t('lang.news'))+" ")]),_c('li',{staticClass:"px-6 text-base text-title-color",class:{
          'menu-active':
            _vm.$route.path === '/blockChainBrowser' ||
            _vm.$route.path === '/block' ||
            _vm.$route.path === '/blockDetail' ||
            _vm.$route.path === '/tradeHash' ||
            _vm.$route.path === '/tradeList' ||
            _vm.$route.path === '/address',
        },on:{"click":function($event){return _vm.goPage('/blockChainBrowser')}}},[_vm._v(" "+_vm._s(_vm.$t('lang.blockChainBrowser'))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }